.profile-bx{
	img{
		height:195px;
		width:195px;
		margin-right:30px;
		@include respond('laptop') {
			height: 155px;
			width: 155px;
			margin-right: 15px;
		}
		@include respond('phone') {
			height: 100px;
			width: 100px;
			margin-right: 15px;
		}
	}
	h2{
		font-size:24px;
		@include respond('phone') {
			font-size:20px;
		}
	}
	.social-icons {
	
		.iconbx{
			height: 56px;
			width: 56px;
			color: #fff;
			background: $primary;
			line-height: 55px;
			text-align: center;
			font-size: 20px;
			margin: 0px 10px 10px 0px;
			display: inline-block;
			float: left;
			@include respond('laptop') {
				height: 41px;
				width: 41px;
				line-height: 41px;
				font-size: 14px;
			}
		}	
	}
}
.contacts-list{

	.media{
		img{
			@include respond('phone') {
				margin-bottom:10px;
			}
		}
		@include respond('phone') {
			flex-wrap: wrap;
			margin-bottom:20px !important;
		}
		.media-body {
			@include respond('phone') {
				flex: none;
				width: 70%;
			}
		}
	}
	
}
.portfolio-chart-deta {
	.col{
		display:flex;
		margin-bottom:30px;
		span{
			height: 33px;
			width: 33px;
			border-radius: 0;
			margin-right: 15px;
		}
		&:last-child{
			margin-bottom:0;
		}
		&:nth-child(1){
			span{
				background:$primary;
			}
		}
		&:nth-child(2){
			span{
				background:#9859E7;
			}
		}
		&:nth-child(3){
			span{
				background:#E1CAFF;
			}
		}
	}
}